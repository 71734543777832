import React from "react"
import ContactForm from "../components/contactform"

import Layout from "../components/layout"
import SEO from "../components/seo"



const Contact = () => {
  //javascript
  const title = 'Contact Us';
  const metatitle = 'Contact Us';
  const description = 'Meta Description for The Contact Us';
  const metadescription = description;
  return(
    <Layout bodyclass="contact" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <ContactForm />
      
    </Layout>
  )
}

export default Contact
