/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import ReactDOM from "react-dom";


import "./formrelation.css";

class ContactForm extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      values: {
        Email: "",
        Name: "",
        Phone: "",
        Comments: ""
      },
      isSubmitting: false,
      isError: false
    };
  }

  submitForm = async e => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isSubmitting: true });

    const res = await fetch("https://api.thebridgedatabase.com/contact-forms", {
      method: "POST",
      body: JSON.stringify(this.state.values),
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.setState({ isSubmitting: false });
    const data = await res.json();
    !data.hasOwnProperty("error")
      ? this.setState({ message: data.success })
      : this.setState({ message: data.error, isError: true });

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          values: { Email: "", Phone: "", Comments: "", Name: "" }
        }),
      1600
    );
  };
      
  handleInputChange = e =>{
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value }
    });
    console.log(this.state.values);
  }
    
  
    
    render(){
        
        
      return(
          <>
        <form className="contact-form" onSubmit={this.submitForm}>
        <label>
        Name:
        <input
            type="text"
            name="Name"
            id="name"
            value={this.state.values.Name || ""}
            onChange={this.handleInputChange}
            title="Name"
            required
        />
        </label>
        <label>
        Email:
        <input
            type="email"
            name="Email"
            id="email"
            value={this.state.values.Email || ""}
            onChange={this.handleInputChange}
            title="Email"
            required
        />
        </label>
        <label>
        Phone:
        <input
            type="text"
            name="Phone"
            id="phone"
            value={this.state.values.Phone || ""}
            onChange={this.handleInputChange}
            title="Phone"
            
        />
        </label>
        <label>
        Message:
        <textarea
            type="text"
            name="Comments"
            id="message"
            value={this.state.values.Comments || ""}
            onChange={this.handleInputChange}
            title="Message"
        ></textarea>
        </label>
        <button type="submit">Submit</button>
    </form>
    <div className={`message ${this.state.isError && "error"}`}>
      {this.state.isSubmitting ? "Submitting..." : this.state.message}
    </div>
    </>
      )
    }
}
export default ContactForm
